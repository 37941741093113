import HubspotForm from 'react-hubspot-form';
import { images } from '../data';
import logo from '../assets/logo.png';
import hubspotStyles  from './hubspotStyles';
import styled from 'styled-components';

import './exelite.css';

const ExElite = () => {

    const handleFormReady = (form) => {
        // wait for script so that it will not give submit error.
        const script = document.createElement('script');
        script.src = 'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js';
        let jqueryScript = document.getElementsByTagName('script');
        let src = Array.from(jqueryScript).filter(item => item.src === script.src);

        // insert css because the form is in inframe.
        if (form) {
            const iframeDocument =
                form.contentDocument || form.contentWindow.document;
            const styleSheet = iframeDocument.createElement('style');
            styleSheet.type = 'text/css';
            styleSheet.id = 'hubspotCustomStyle';
            styleSheet.innerHTML = hubspotStyles;
            iframeDocument.head.appendChild(styleSheet);
        }
        if(src.length === 0) document.body.appendChild(script);
    };

    const handleSubmit = () => {
        setTimeout(() => {
            window.scrollTo({ top:0, behavior: 'smooth' })
        }, 500);
        setTimeout(() => {
            window.location.reload();
        }, 3000);
    }


  return (
    <div className='exelite__container'>
    <div className='exelite__content'>
        <div className='exelite__left__content'>
            <div className='exelite__left__inner'>
            <div className='logo'>
                <img src={logo} alt="logo" />
                <div className='tagLine'>
                    Register to win £100 and other exciting gifts
                </div>
            </div>
            <FormContainer className='form'>
                <HubspotForm
                    portalId = "139645438"
                    formId = "4f13a9cf-5bba-4fc9-ba9b-ce3f7ffa268d"
                    onReady={handleFormReady}
                    onSubmit = {handleSubmit}
                    loading={
                        <div
                            style={{
                                border: '5px solid #c3bfbf',
                                borderTop: '5px solid #825645',
                                borderRadius: '50%',
                                width: '30px',
                                height: '30px',
                                animation: 'spin 1.5s linear infinite',
                                margin: '100px auto'
                            }}
                        ></div>
                    }
                    />
            </FormContainer>
        </div>
        </div>
        <div className='exelite__right__content'>
            <div className='top__content'>
                <div>YOUR TRUSTED</div>
                <div>AB TEST DEVELOPMENT PARTNER</div>
            </div>
            <hr />
            <div className='middle__content'>
                <div className='first'>
                    <h2>20,000+</h2>
                    <p>Experiments</p> 
                        <p>coded</p>
                </div>
                <div className='second'>
                <h2>100+</h2>
                <p>AB Test</p> 
                    <p>Developers</p>
                </div>
                <div className='third'>
                <h2>~3</h2>
                <p>Days to deliver</p>
                    <p>an experiment</p>
                </div>
            </div>
            <hr />
            <div className='bottom__content'>
                <h2>Trusted By</h2>
                <div className='bottom__content__images'>
                    <div className='cont desktop'>
                    {images.slice(0,4).map((indiImage) => (
                        <div key={indiImage.id} className={indiImage.class}>
                        <img src={indiImage.image} alt={indiImage.id} />
                    </div>
                    ))}
                    </div>
                    <div className='cont desktop'>

                    {images.slice(4,8).map((indiImage) => (
                        <div key={indiImage.id} className={indiImage.class}>
                            <img src={indiImage.image} alt={indiImage.id} />
                    </div>
                    ))}
                    </div>
                    <div className='cont last desktop'>

                    {images.slice(8,10).map((indiImage) => (
                        <div key={indiImage.id} className={indiImage.class}>
                        <img src={indiImage.image} alt={indiImage.id} />
                    </div>
                    ))}
                    </div>

                    <div className='cont mobile'>
                    {images.map((indiImage) => (
                        <div key={indiImage.id} className={indiImage.class}>
                        <img src={indiImage.image} alt={indiImage.id} />
                    </div>
                    ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default ExElite;

const FormContainer = styled.div``