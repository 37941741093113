import  image5 from './assets/image5.png'
import  image6 from './assets/image6.png'
import  image7 from './assets/image7.png'
import  image8 from './assets/image8.png'
import  image9 from './assets/image9.png'
import  image10 from './assets/image10.png'
import  image11 from './assets/image11.png'
import  image12 from './assets/image12.png'
import  image13 from './assets/image13.png'
import  image14 from './assets/image14.png'

export const images = [
    {
        id: 1,
        image: image5,
        class: 'taj'
    },
    {
        id: 2,
        image: image6,
    class: 'subaro'
    },
    {
        id: 3,
        image: image7,
    class: 'toyota'
    },
    {
        id: 4,
        image: image8,
    class: 'yelp'
    },
    {
        id: 5,
        image: image10,
    class: 'schibested'
    },
    {
        id: 6,
        image: image9,
    class: 'jabra'
    },
    {
        id: 7,
        image: image11,
    class: 'ego'
    },
    {
        id: 8,
        image: image12,
    class: 'peterhan'
    },
    {
        id: 9,
        image: image13,
    class: 'jellyfish'
    },
    {
        id: 10,
        image: image14,
    class: 'delloite'
    }
]