const hubspotStyles = `
@font-face {
    font-family: 'Euronics-Black';
    src: url('../fonts/Euronics-Black.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'Euronics-Bold';
    src: url('../fonts/Euronics-Bold.otf') format('opentype');
  }
  
  @font-face {
    font-family: 'Euronics-Heavy';
    src: url('../fonts/Euronics-Heavy.otf') format('opentype');
  }
  @font-face {
    font-family: 'Euronics-Regular';
    src: url('../fonts/euronics-regular.woff') format('woff');
  }

[class *= "hsForm_"] .hs-form-field label:not(.hs-error-msg) {
    font-size: 15px;
    color: #444;
    margin-bottom: 8px;
    font-family: 'Euronics-Regular' !important;
}

[class *= "hsForm_"] .hs-form-field.hs_numemployees label:not(.hs-error-msg), [class *= "hsForm_"] .hs-form-field.hs_company_size label:not(.hs-error-msg) {
    height: 40px;
}

form[id*="hsForm"] {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

form[id*="hsForm"] > div {
    margin-bottom: 30px;
}

form[id*="hsForm"] .hs_firstname,
form[id*="hsForm"] .hs_email,
form[id*="hsForm"] .hs_company,
form[id*="hsForm"] .hs-fieldtype-select,
form[id*="hsForm"] .hs_company_size,
form[id*="hsForm"] .hs_industry {
    max-width: 48%;
    width: 100%;
}

.hs-custom-style>div>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]), .hs-custom-style fieldset>div:last-of-type .hs-input:not([type=checkbox]):not([type=radio]) {
    width: 100%;
    max-width: 48%;
}

form[id*="hsForm"] .hs_email {
    height: 99px;
    margin-bottom: 12px;
}

form[id*="hsForm"] .hs-form-field .input input, form[id*="hsForm"] .hs-form-field .input select  {
    height: 48px;
    border-radius: 3px;
    background: #FFF;
    border: 1px solid #825645;
    font-size: 14px;
    padding-left: 12px !important;
}

form[id*="hsForm"] .hs_hs_lead_status.hs-form-field .input input {
    height: auto;
    border-radius: 0;
    background: #FFF;
    border: none;
}

    .hs_hs_lead_status .input .inputs-list.multi-container {
    display: flex;
    align-items: center;
    }

    .hs_hs_lead_status .input .inputs-list.multi-container .hs-form-radio {
    width: auto;
    }

    .hs_hs_lead_status .input .inputs-list.multi-container .hs-form-radio:first-child {
    margin-right: 82px;
    }

    .hs_hs_lead_status .input .inputs-list.multi-container .hs-form-radio label {
    display: flex;
    align-items: center;
    }

    .hs_hs_lead_status .input .inputs-list.multi-container .hs-form-radio span {
    font-size: 16px;
    font-family: 'Euronics-Regular' !important;
    }

    .hs_hs_lead_status .input .inputs-list.multi-container .hs-form-radio input[type="radio"] {
    appearance: none; 
    -webkit-appearance: none;
    height: 21px;
    width: 21px;
    border: 1px solid #825645; 
    border-radius: 50%;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    position: relative;
  }

  .hs_hs_lead_status .input .inputs-list.multi-container .hs-form-radio input[type="radio"]:checked::before {
    content: "";
    width: 11px;
    height: 11px;
    margin: 0;
    border-radius: 50%;
    background-color: #825645;
    position: absolute;
    left: 50%;
    right: 0px;
    top: 50%;
    transform: translate(-52%, -55%);
    }

    form[id*="hsForm"] .hubspot-link__container.sproket {
        display: none;
    }

    form[id*="hsForm"] .hs-submit {
       width: 100%;
       margin-bottom: 0;
    }

    form[id*="hsForm"] .hs-submit .actions {
       margin: 0;
       padding: 0;
    }
    form[id*="hsForm"] .hs-submit .hs-button {
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.36px;
        background: linear-gradient(360deg, #382A52 0%, #825645 99%);
        background: -webkit-linear-gradient(360deg, #382A52 0%, #825645 99%);
        border: 0;
        width: 100%;
        height: 54px;
        font-family: 'Euronics-Regular' !important;
    }

    .hbspt-form .submitted-message {
        font-family: 'Euronics-Regular' !important;
        text-align: center;
    }

@media only screen and  (max-width: 1048px) {
    [class *= "hsForm_"] .hs-form-field label:not(.hs-error-msg) {
        font-size: 13px;
    }
}

@media only screen and  (max-width: 948px) {
    [class *= "hsForm_"] .hs-form-field label:not(.hs-error-msg) {
     font-size: 12px;
 }
 }

 @media only screen and (max-width: 917px) {
    [class *= "hsForm_"] .hs-form-field label:not(.hs-error-msg) {
     font-size: 10px;
 }
 }

 @media only screen and  (max-width: 874px) {
    [class *= "hsForm_"] .hs-form-field label:not(.hs-error-msg) {
        font-size: 14px;
    }
} 


@media only screen and  (max-width: 478px) {
    [class *= "hsForm_"] .hs-form-field label:not(.hs-error-msg) {
        font-size: 12px;
    }
}

@media only screen and (max-width: 417px) {
    form[id*="hsForm"] .hs_firstname,
    form[id*="hsForm"] .hs_email,
    form[id*="hsForm"] .hs_company,
    form[id*="hsForm"] .hs-fieldtype-select,
    form[id*="hsForm"] .hs_company_size,
    form[id*="hsForm"] .hs_industry {
        max-width: 100%;
        width: 100%;
    }
}
`;

export default hubspotStyles;
