import ExElite from './pages/ex-elite';

function App() {
  return (
    <div className="App">
      <ExElite />
    </div>
  );
}

export default App;
